<template>
  <div class="flex flex-col items-center pt-20 md:pt-0 md:min-h-screen md:justify-center px-4">
    <div>
      <img src="./assets/logo.png"  class="w-48 h-auto">
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {}
</script>
