<template>
    <div class="rounded shadow-md bg-white w-full sm:w-1/2 xl:w-2/5 my-4 p-6">
        <form @submit.prevent="setPassword" class="grid gap-6">
            <div class="grid uppercase text-gray-600 leading-tight font-semibold">
              <label>New Password</label>
              <input
                type="password"
                class="border border-gray-400 rounded p-2 leading-normal text-black"
                data-testId="password"
                v-model="password"
              >
            </div>
            <div class="grid uppercase text-gray-600 leading-tight font-semibold">
              <label>Confirm New Password</label>
              <input
                type="password"
                class="border border-gray-400 rounded p-2 leading-normal text-black"
                data-testId="confirm-password"
                v-model="confirm"
              >
            </div>
            <div>
              <div
                data-testId="error"
                class="rounded bg-red-200 text-red-800 p-3 leading-normal"
                v-if="error"
              >{{error}}</div>
              <div data-testId="message-success" class="rounded bg-green-200 text-green-800 p-3 leading-normal" v-if="success">
                Your new password has been set. Login below.
              </div>
            </div>

            <div class="grid gap-6 justify-center">
              <input
                class="btn"
                type="submit"
                value="Set New Password"
                :disabled="processing"
              >
              <router-link
                class="text-blue-500 text-center"
                v-if="success"
                to="/"
              >Login</router-link>
            </div>
        </form>
      </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ResetPassword',
  data() {
    return {
      processing: false,
      password: '',
      confirm: '',
      success: false,
      error: false
    }
  },
  methods: {}
}
</script>
