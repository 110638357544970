<template>
  <div class="rounded shadow-md bg-white w-full max-w-xs mt-4">
    <div class="p-6">
      <form @submit.prevent="login">
        <div class="grid gap-6">
          <div class="grid uppercase text-gray-600 leading-tight font-semibold">
            <label>Email</label>
            <input
              type="email"
              autocomplete="email"
              data-testId="email"
              class="
                border border-gray-400
                rounded
                p-2
                leading-normal
                text-black
              "
              v-model="email"
            />
          </div>
          <div class="grid uppercase text-gray-600 leading-tight font-semibold">
            <label>Password</label>
            <input
              type="password"
              autocomplete="current-password"
              ref="passwordInput"
              data-testId="password"
              class="
                border border-gray-400
                rounded
                p-2
                leading-normal
                text-black
              "
              v-model="password"
            />
          </div>
          <div>
            <div
              data-testId="message-reset"
              class="rounded bg-green-200 text-green-800 p-3 leading-normal"
              v-if="message"
            >
              {{ message }}
            </div>
            <div
              data-testId="message-error"
              class="rounded bg-red-200 text-red-800 p-3 leading-normal"
              v-if="error"
            >
              {{ error }}
            </div>
          </div>
          <div class="text-center">
            <input
              type="submit"
              value="Login"
              class="btn"
              :disabled="processing"
            />
          </div>
          <div class="text-center">
            <button
              type="button"
              class="text-blue-500"
              @click="presentPasswordHelp = true"
            >
              Forgot password?
            </button>
          </div>
        </div>
      </form>
    </div>
    <PasswordHelp
      v-if="presentPasswordHelp"
      @reset-success="handleReset"
      @dismiss="presentPasswordHelp = false"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import PasswordHelp from '../components/PasswordHelpModal'

export default {
  name: 'Login',
  components: {
    PasswordHelp
  },
  data() {
    return {
      email: '',
      password: '',
      error: '',
      processing: false,
      message: '',
      presentPasswordHelp: false
    }
  },
  mounted() {
    // Attempt auto-login. We only support the PasswordCredential store.
    if (window.PasswordCredential) {
      navigator.credentials
        .get({
          password: true,
          unmediated: true
        })
        .then((cred) => {
          if (cred) {
            this.email = cred.id
            this.$refs.passwordInput.value = cred.password
            this.login()
          }
        })
    }
  },
  methods: {
    async login() {
      const email = this.email
      const password = this.$refs.passwordInput.value // hack to work around https://stackoverflow.com/questions/35049555/chrome-autofill-autocomplete-no-value-for-password

      if (!email || !password) {
        this.error = 'Please fill out all required fields.'
        return
      }

      this.message = null
      this.error = null
      this.processing = true

      try {
        const { data } = await this.$apollo.mutate({
          mutation: gql`
            mutation ($input: employeeLoginInput!) {
              EmployeeLogin(employeeLoginInput: $input) {
                id
                employeeId
              }
            }
          `,
          variables: {
            input: { email, password }
          }
        })

        window.localStorage.userId = data.EmployeeLogin.employeeId
        window.localStorage.userToken = data.EmployeeLogin.id
        document.cookie = `userId=${data.EmployeeLogin.employeeId};max-age=2678400;path=/`
        document.cookie = `userToken=${data.EmployeeLogin.id};max-age=2678400;path=/`

        // Store credentials in PasswordCredential store.
        if (window.PasswordCredential) {
          await navigator.credentials.store(
            new PasswordCredential({
              id: email,
              name: email,
              password: password
            })
          )
        }

        // Redirect to app.
        window.location.href = '/sales/dash?t=' + new Date().getTime()
      } catch (err) {
        if (err.graphQLErrors && err.graphQLErrors.length) {
          this.error = err.graphQLErrors.map((e) => e.message).join(' ')
        } else {
          this.error = err.message
        }
        this.processing = false
        return
      }
    },
    handleReset(email) {
      this.error = null
      this.presentPasswordHelp = false
      this.message = `An email has been sent to ${email} with further instructions.`
    }
  }
}
</script>
