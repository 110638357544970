<template>
  <div>
    <div
      class="absolute inset-0 w-full h-full bg-black bg-opacity-75"
      @click="$emit('dismiss')"
    ></div>

    <div
      class="
        absolute absolute-center
        bg-white
        rounded
        w-5/6
        sm:w-1/2
        md:w-1/3
        lg:w-1/4
        xl:w-1/5
        p-4
        z-50
      "
    ></div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'PasswordHelp',
  data() {
    return {
      error: null,
      processing: false,
      email: ''
    }
  },
  methods: {}
}
</script>

<style scoped>
.absolute-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
