import Vue from 'vue'
import App from './App.vue'
import { router } from './router'

import VueApollo from 'vue-apollo'
import apollo from './lib/apollo'

// Configure VueApollo.
Vue.use(VueApollo)
const apolloProvider = new VueApollo({
  defaultClient: apollo
})

const vm = new Vue({
  components: { App },
  apolloProvider,
  router,
  template: '<App/>'
})

vm.$mount('#app')
