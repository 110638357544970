import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from './views/Login'
import ResetPassword from './views/ResetPassword'

Vue.use(VueRouter)

export const router = new VueRouter({
  base: '/',
  mode: 'history',

  routes: [
    {
      path: '/',
      component: Login,
    },
    {
      path: '/reset-password/:userId/:token',
      component: ResetPassword
    },
    {
      path: '*',
      component: Login,
    }
  ]
})
