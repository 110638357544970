import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

const link = createHttpLink({
  fetch: (uri, options) => {
    uri = process.env.VUE_APP_API_HOST
    return fetch(uri, options)
  }
})

// Create the apollo client
const apollo = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    mutate: {
      errorPolicy: 'none'
    },
    query: {
      errorPolicy: 'none',
      fetchPolicy: 'network-only'
    },
    watchQuery: {
      errorPolicy: 'none',
      fetchPolicy: 'cache-and-network'
    }
  }
})

export default apollo
